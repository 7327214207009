import React from "react";
import About from "../components/HomePage/About";

const WhyChooseUs = () => {
  return (
    <div className="container my-5">
      <div className="text-center mb-5">
        <h2 className="why-choose" style={{
          fontSize: '2.5rem',
          fontWeight: '700',
          color: '#2c3e50'
        }}>
          Why Choose Us: Precision, Insight, and Effective Marketing Solutions.
        </h2>
      </div>
      <p className="mt-5" style={{
        fontSize: '1.1rem',
        lineHeight: '1.7',
        color: '#4a5568'
      }}>
        Choose our tool for unparalleled lead generation tailored to eCommerce businesses. It offers comprehensive insights, including CMS type, SEO performance, and loading speed, empowering digital marketers to create highly targeted and personalized outreach strategies. By leveraging these detailed metrics, marketers can address specific issues and opportunities, ensuring their pitches are relevant and impactful. This precision enhances engagement and effectiveness, helping you achieve better results in your marketing efforts.
      </p>
      
      <About />
    </div>
  );
};

export default WhyChooseUs;