import React from "react";
import ContactUs from "./ContactUs";

const ContactPage = () => {


  return (
    <>
      <ContactUs></ContactUs>
    </>
  );
};

export default ContactPage;
