import React from 'react';

const Loading = () => {
  return (
    <>
      <div id="preloader">
        <div id="metize-preloader" class="metize-preloader">
          <div class="animation-preloader">
            <div class="spinner"></div>
          </div>
          <div class="loader">
            <div class="row">
              <div class="col-3 loader-section section-left">
                <div class="bg"></div>
              </div>
              <div class="col-3 loader-section section-left">
                <div class="bg"></div>
              </div>
              <div class="col-3 loader-section section-right">
                <div class="bg"></div>
              </div>
              <div class="col-3 loader-section section-right">
                <div class="bg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
