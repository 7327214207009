import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Search, Building2, Users2, ArrowUpRight, Loader2, X, Mail, Globe, DollarSign, Briefcase, User, Users, MapPin,
  Facebook, Linkedin, Speed, Box, Activity, Eye
} from 'lucide-react';

const MyLeadView = () => {
  // State Management
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [shuffledData, setShuffledData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasSearched, setHasSearched] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  // Add modal state
  const [selectedLead, setSelectedLead] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (lead) => {
    setSelectedLead(lead);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedLead(null);
    setIsModalOpen(false);
  };

  const itemsPerPage = 10;

  // Utility Functions
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Data Fetching
  useEffect(() => {
    setIsLoading(true);
    fetch(`https://raw.githubusercontent.com/Shah-Limon/canva-image-4-projects/refs/heads/main/Real-Estate-Email-Database.json`)
      .then((res) => {
        if (!res.ok) throw new Error('Network response was not ok');
        return res.json();
      })
      .then((data) => {
        setJsonData(data);
        setShuffledData(shuffleArray(data));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  // Search and Filtering
  const filteredData = jsonData.filter(lead =>
    lead.jobTitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (searchQuery) {
      setCurrentPage(1);
      setShuffledData(shuffleArray(filteredData));
      setHasSearched(true);
    } else {
      setShuffledData([]);
      setHasSearched(false);
    }
  }, [searchQuery, jsonData]);

  // Pagination
  const rowNumber = (currentPage - 1) * itemsPerPage + 1;
  const totalPages = Math.ceil(shuffledData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Animated Stats
  const renderStats = () => (
    <div className="row mb-4 opacity-0 animate-fade-in">
      <div className="col-md-4 mb-4 mb-md-0">
        <div className="card shadow-sm h-100">
          <div className="card-body">
            <div className="d-flex align-items-center space-x-3">
              <Users2 className="text-primary" size={32} />
              <div>
                <p className="text-muted mb-0">Total Contacts</p>
                <p className="h4 mb-0 font-weight-bold text-dark">
                  {jsonData.length.toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-4 mb-md-0">
        <div className="card shadow-sm h-100">
          <div className="card-body">
            <div className="d-flex align-items-center space-x-3">
              <Building2 className="text-primary" size={32} />
              <div>
                <p className="text-muted mb-0">Industries</p>
                <p className="h4 mb-0 font-weight-bold text-dark">
                  {new Set(jsonData.map(lead => lead.productType)).size}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card shadow-sm h-100">
          <div className="card-body">
            <div className="d-flex align-items-center space-x-3">
              <ArrowUpRight className="text-primary" size={32} />
              <div>
                <p className="text-muted mb-0">Avg. SEO Score</p>
                <p className="h4 mb-0 font-weight-bold text-dark">
                  {Math.round(jsonData.reduce((acc, lead) => acc + parseInt(lead.seoScore), 0) / jsonData.length)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Component Renders
  const renderSearchBox = () => (
    <div className="mb-4 mx-auto max-w-2xl transform transition-all duration-300">
      <div className={`position-relative ${isSearchFocused ? 'scale-105' : ''}`}>
        <input
          type="text"
          placeholder="Search by Person Title (e.g., 'CEO', 'Manager')"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
          className="form-control form-control-lg ps-5 
                   focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
                   transition-all duration-300"
        />
        <Search className={`position-absolute left-4 top-1/2 -translate-y-1/2 transition-colors duration-300
                          ${isSearchFocused ? 'text-blue-500' : 'text-gray-400'}`}
          size={20} />
      </div>
    </div>
  );

  const renderTableHeader = () => (
    <thead className="bg-primary">
      <tr>
        {['SL No.', 'Person Name', 'Person Email', 'Website', 'Performance', 'Revenue', 'View']
          .map(header => (
            <th key={header}
              className="px-3 py-2 text-left text-xs font-medium text-white uppercase">
              {header}
            </th>
          ))}
      </tr>
    </thead>
  );

  const Modal = ({ show, onClose, lead }) => {
    // Function to format the URL
    const formatUrl = (url) => {
      // Check if the URL starts with 'http://' or 'https://'
      if (!/^https?:\/\//i.test(url)) {
        return `http://${url}`;
      }
      return url;
    };

    if (!show) return null;

    const handleModalClick = (e) => {
      e.stopPropagation();
    };

    // Function to render metric card with score
    const MetricCard = ({ label, value, score, maxScore, color }) => (
      <div className="card border border-gray-200 bg-white">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="text-muted">{label}</span>
            <span className={`font-weight-bold ${color}`}>{score}/{maxScore}</span>
          </div>
          <div className="progress h-2">
            <div
              className={`progress-bar ${color.replace('text', 'bg')}`}
              style={{ width: `${(score / maxScore) * 100}%` }}
            ></div>
          </div>
          <span className="text-muted mt-1">{value}</span>
        </div>
      </div>
    );

    return (
      <div className="modal d-block d-flex align-items-center justify-content-center">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div
            onClick={handleModalClick}
            className="modal-content bg-white/95 rounded-3 shadow-2xl animate-in slide-in-from-bottom-4 hover:shadow-blue-500/20"
          >
            {/* Decorative elements */}
            <div className="position-absolute -top-1 -left-1 w-24 h-24 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full opacity-20 blur-xl animate-pulse"></div>
            <div className="position-absolute -bottom-1 -right-1 w-24 h-24 bg-gradient-to-br from-purple-500 to-pink-500 rounded-full opacity-20 blur-xl animate-pulse"></div>

            {/* Header */}
            <div className="border-bottom border-gray-200/80 px-4 py-2 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center space-x-3">
                <div className="p-2 bg-gradient-to-br from-blue-500/10 to-purple-500/10 rounded-lg">
                  <Building2 className="text-blue-600" size={24} />
                </div>
                <div>
                  <h3 className="h2 font-weight-bold text-gradient-to-r from-blue-600 to-purple-600">
                    {lead.companyName}
                  </h3>
                  <p className="text-muted">{lead.niche}</p>
                </div>
              </div>
              <button
                onClick={onClose}
                className="btn btn-light rounded-circle p-2 hover-bg-gray-100 transition-colors duration-200"
              >
                <X className="text-gray-400 group-hover:text-gray-600" size={24} />
              </button>
            </div>

            {/* Content */}
            <div className="p-4 space-y-4 relative">
              {/* Top summary section */}
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="card border border-gray-200 bg-gradient-to-br from-blue-50 to-blue-100/50">
                    <div className="card-body">
                      <h4 className="card-title text-muted mb-2">Company Overview</h4>
                      <div className="space-y-2">
                        <div className="d-flex align-items-center space-x-2">
                          <Globe className="text-purple-600" size={16} />
                          <a href={formatUrl(lead.website)} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover-underline">{lead.website}</a>
                        </div>
                        <div className="d-flex align-items-center space-x-2">
                          <Mail className="text-purple-600" size={16} />
                          <a href={`mailto:${lead.companyEmail}`} className="text-blue-600 hover-underline">{lead.companyEmail}</a>
                        </div>
                        <div className="d-flex align-items-center space-x-2">
                          <Users className="text-blue-600" size={16} />
                          <span className="text-gray-700">Employees: {lead.totalEmployees}</span>
                        </div>
                        <div className="d-flex align-items-center space-x-2">
                          <DollarSign className="text-green-600" size={16} />
                          <span className="text-gray-700">Revenue: {lead.revenue}</span>
                        </div>
                        <div className="d-flex align-items-center space-x-2">
                          <MapPin className="text-red-600" size={16} />
                          <span className="text-gray-700">{lead.address}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="card border border-gray-200 bg-gradient-to-br from-purple-50 to-purple-100/50">
                    <div className="card-body">
                      <h4 className="card-title text-muted mb-2">Contact Information</h4>
                      <div className="space-y-2">
                        <div className="d-flex align-items-center space-x-2">
                          <User className="text-purple-600" size={16} />
                          <span className="text-gray-700">{lead.personName} - {lead.jobTitle}</span>
                        </div>
                        <div className="d-flex align-items-center space-x-2">
                          <Mail className="text-purple-600" size={16} />
                          <a href={`mailto:${lead.personEmail}`} className="text-blue-600 hover-underline">{lead.personEmail}</a>
                        </div>
                        <div className="d-flex align-items-center space-x-2">
                          <Globe className="text-purple-600" size={16} />
                          <a href={formatUrl(lead.website)} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover-underline">{lead.website}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Technical Metrics */}
              <div className="space-y-4">
                <h4 className="h5 font-weight-semibold text-gray-800">Technical Analysis</h4>
                <div className="row g-4">
                  <div className="col-md-4">
                    <MetricCard
                      label="SEO Score"
                      value="Search Engine Optimization"
                      score={parseInt(lead.SEOScore)}
                      maxScore={100}
                      color="text-warning"
                    />
                  </div>
                  <div className="col-md-4">
                    <MetricCard
                      label="Loading Speed"
                      value={lead["Loading Speed"]}
                      score={20}
                      maxScore={100}
                      color="text-danger"
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="card border border-gray-200 bg-white">
                      <div className="card-body">
                        <h5 className="card-title text-muted mb-2">CMS Platform</h5>
                        <div className="d-flex align-items-center space-x-2">
                          <Box className="text-blue-600" size={20} />
                          <span className="font-medium text-gray-700">
                            {lead.CMSType ? lead.



CMSType : "No CMS Type Provided"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Social Links */}
              <div className="space-y-4">
                <h4 className="h5 font-weight-semibold text-gray-800">Social Presence</h4>
                <div className="d-flex space-x-4">
                  {lead.linkedinLink && (
                    <a
                      href={lead.linkedinLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-3 rounded-lg bg-blue-50 hover-bg-blue-100 transition-colors duration-200"
                    >
                      <Linkedin className="text-blue-700" size={24} />
                    </a>
                  )}
                  {lead.facebookLink && (
                    <a
                      href={lead.facebookLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-3 rounded-lg bg-blue-50 hover-bg-blue-100 transition-colors duration-200"
                    >
                      <Facebook className="text-blue-700" size={24} />
                    </a>
                  )}
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className="px-4 py-3 border-top border-gray-200/80 bg-gray-50/50 d-flex justify-content-between align-items-center rounded-b-3">
              <div className="d-flex align-items-center space-x-2">
                <Activity className="text-green-500" size={16} />
                <span className="text-gray-600">Last updated: Recently</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTableRow = (lead, index) => (
    <tr key={index}
      onClick={() => openModal(lead)}
      className="hover-bg-blue-50 transition-colors duration-200 animate-fade-in"
      style={{ animationDelay: `${index * 50}ms` }}>
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
        {rowNumber + index}
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{lead.personName}</div>
        <div className="text-sm font-medium text-primary">
          - {lead.jobTitle.length > 30 ? `${lead.jobTitle.slice(0, 30)}...` : lead.jobTitle}
        </div>
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <div className="text-sm text-gray-900">{lead.personEmail}</div>
        <div className="text-sm text-success d-flex align-items-center">
          <span className="bg-success rounded-full mr-2" style={{ width: '8px', height: '8px' }}></span>
          Valid Email
        </div>
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <div className="text-sm text-gray-900">{lead.website}
        </div>
        <div className="text-sm text-gray-500">
          -CMS: {lead.CMSType ? lead.CMSType : "No CMS Found"}
        </div>
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <div className="text-sm text-gray-900">Loading Speed: {lead.loadingSpeed}</div>
        <div className="d-flex align-items-center">
          <div className="text-sm text-gray-500">SEO Score: </div>
          <div className="ml-1 px-2 py-1 text-xs font-medium bg-blue-100 text-blue-800 rounded">
            {lead.SEOScore}
          </div>
        </div>
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <span className="text-sm text-gray-500">{lead.revenue}</span>
      </td>
      <td className="px-3 py-2 whitespace-nowrap">
        <button
          onClick={() => openModal(lead)}
          className="btn btn-outline-primary btn-sm rounded-lg px-4 py-2 
                 transition-all duration-300 ease-in-out
                 hover:bg-gradient-to-r hover:from-indigo-50 hover:to-purple-50
                 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {/* Background shine effect */}
          <div className="position-absolute inset-0 rounded-lg bg-gradient-to-r from-transparent via-white/20 to-transparent 
                      translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000">
          </div>

          {/* Icon with animation */}
          <Eye className="text-indigo-600 transition-transform duration-300 
                     group-hover:scale-110 group-hover:text-indigo-700" size={16} />

          {/* Text with gradient effect */}
          <span className="text-sm font-medium bg-gradient-to-r from-indigo-600 to-indigo-800 
                      bg-clip-text text-transparent transition-all duration-300
                      group-hover:from-indigo-700 group-hover:to-indigo-900">
            View Details
          </span>

          {/* Animated border */}
          <div className="position-absolute -inset-px rounded-lg opacity-0 group-hover:opacity-100
                      transition-all duration-300 group-hover:duration-700">
            <div className="position-absolute inset-0 rounded-lg bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-indigo-500/20
                        animate-border-shine">
            </div>
          </div>
        </button>
      </td>
    </tr>
  );

  const renderPagination = () => (
    <nav aria-label="Pagination" className="mt-4 d-flex justify-content-center">
      <ul className="pagination rounded-lg shadow-md overflow-hidden">
        {currentPage > 1 && (
          <li className="page-item">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="page-link bg-white border-r border-gray-200 
                     text-gray-500 hover-bg-gray-50 
                     transition-colors duration-200"
            >
              Previous
            </button>
          </li>
        )}

        {Array.from({ length: totalPages }, (_, i) => i + 1)
          .filter(page => {
            if (currentPage <= 2) return page <= 3;
            if (currentPage >= totalPages - 1) return page >= totalPages - 2;
            return page >= currentPage - 1 && page <= currentPage + 1;
          })
          .map(page => (
            <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
              <button
                onClick={() => handlePageChange(page)}
                className={`page-link bg-white border-r border-gray-200 
                         transition-colors duration-200 ${currentPage === page ? 'bg-primary text-white' : 'text-gray-500 hover-bg-gray-50'}`}
              >
                {page}
              </button>
            </li>
          ))}

        {currentPage < totalPages && (
          <li className="page-item">
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="page-link bg-white 
                     text-gray-500 hover-bg-gray-50
                     transition-colors duration-200"
            >
              Next
            </button>
          </li>
        )}
      </ul>
    </nav>
  );

  const renderEmptyState = () => (
    <div className="space-y-8 animate-fade-in">
      <div className="text-center py-8 text-gray-600">
        <p className="transform transition-all duration-300 hover:scale-105">
          Please enter a job title above to find email contacts in the real estate industry.
        </p>
      </div>
      <div className="max-w-3xl mx-auto text-center space-y-8">
        <div className="space-y-4">
          <h2 className="h1 font-weight-bold text-gray-900 tracking-tight">
          Find Real Estate Email by{' '}
            <span className="text-primary relative inline-block group">
              Job Title
              <div className="position-absolute -bottom-2 left-0 w-full h-1 bg-blue-600 
                            transform scale-x-0 group-hover:scale-x-100 
                            transition-transform duration-300"></div>
            </span>
          </h2>
          <p className="h4 text-gray-600 leading-relaxed">
            Access our database of{' '}
            <span className="font-semibold text-primary">verified</span>{' '}
            business contacts. Search by job title to connect with the right decision-makers.
          </p>
        </div>
      </div>
      {renderStats()}
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-5">
      <div className="container">
        <h1 className="h1 font-weight-bold text-center mb-5 animate-fade-in">
          Real Estate Email Database
        </h1>

        {renderSearchBox()}

        {isLoading ? (
          <div className="text-center py-8 animate-fade-in">
            <Loader2 className="h-8 w-8 animate-spin text-blue-500 mx-auto" />
            <p className="mt-4 text-gray-600">Loading leads data...</p>
          </div>
        ) : error ? (
          <div className="text-center py-8 text-danger animate-fade-in">
            <p>Error loading data: {error}</p>
          </div>
        ) : hasSearched && filteredData.length > 0 ? (
          <div className="space-y-6 animate-fade-in">
            <div className="overflow-x-auto bg-white shadow-xl rounded-lg">
              <table className="table table-auto">
                {renderTableHeader()}
                <tbody className="divide-y divide-gray-200">
                  {shuffledData
                    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                    .map((lead, index) => renderTableRow(lead, index))}
                </tbody>
              </table>
            </div>
            {renderPagination()}
          </div>
        ) : hasSearched ? (
          <div className="text-center py-8 text-gray-600 animate-fade-in">
            <p>No leads found matching your search. Please try different keywords.</p>
          </div>
        ) : renderEmptyState()}
        <Modal show={isModalOpen} onClose={closeModal} lead={selectedLead} />
      </div>
    </div>
  );
};

const WrappedMyLeadView = () => (
  <>
    <style>
      {`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fade-in {
          animation: fadeIn 0.5s ease-out forwards;
        }

        .hover-bg-gray-100:hover {
          background-color: #f3f4f6;
        }

        .hover-bg-blue-50:hover {
          background-color: #f0f9ff;
        }

        .hover-underline:hover {
          text-decoration: underline;
        }

        .animate-border-shine {
          animation: borderShine 2s ease-in-out infinite;
        }

        @keyframes borderShine {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }
      `}
    </style>
    <MyLeadView />
  </>
);

export default WrappedMyLeadView;