import React, { useState, useEffect } from "react";

const PageHero = () => {
  const [bannerHeight, setBannerHeight] = useState("7rem");

  useEffect(() => {
    const updateHeight = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setBannerHeight("0rem"); // Small devices
      } else if (width <= 1023) {
        setBannerHeight("0rem"); // Medium devices
      } else {
        setBannerHeight("5.5rem"); // Large devices
      }
    };

    // Initial height calculation
    updateHeight();

    // Add resize event listener
    window.addEventListener("resize", updateHeight);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div
      className="banner-software text-center text-light"
      style={{
        backgroundImage: "url(/assets/img/shape/banner-1.jpg)",
        height: bannerHeight,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="content-box">
          <div className="row align-center">
            {/* Add your content here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHero;
