import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [logo, setLogo] = useState({});
  const [footer, setFooter] = useState({});
  const [social, setSocial] = useState({});
  useEffect(() => {
    fetch(`https://server.enjoywiki.com/hunt-crow/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info[0]));
  }, []);
  useEffect(() => {
    fetch(`https://server.enjoywiki.com/hunt-crow/footer-links`)
      .then((res) => res.json())
      .then((info) => setFooter(info[0]));
  }, []);
  useEffect(() => {
    fetch(`https://server.enjoywiki.com/hunt-crow/footer-social`)
      .then((res) => res.json())
      .then((info) => setSocial(info[0]));
  }, []);

  const [contact, setContact] = useState({});

  useEffect(() => {
    fetch(`https://server.enjoywiki.com/hunt-crow/contact/`)
      .then((res) => res.json())
      .then((info) => setContact(info[0]));
  }, []);

  return (
    <>
      <footer className="bg-dark text-light">
        <div className="container">
          <div className="f-items default-padding-bottom pt-70 pt-xs-0">
            <div className="row">
              {/* Singel Item */}
              <div className="col-lg-4 col-md-6 footer-item mt-50">
                <div className="f-item about pr-50 pr-xs-0 pr-md-0">
                  <img className="logo" src={logo.logo} alt="Logo" />
                  <p>
                    {footer.FooterAbout}
                  </p>

                </div>
              </div>
              {/* End Singel Item */}
              {/* Singel Item */}
              <div className="col-lg-4 col-md-6 mt-50 footer-item pl-50 pl-md-15 pl-xs-15">
                <div className="f-item link">
                  <h4 className="widget-title">Links</h4>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>

                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-condition">Terms</Link>
                    </li>

                  </ul>
                </div>
              </div>
          
              <div className="col-lg-4 col-md-6 footer-item mt-50">
                <div className="f-item newsletter">
                  <h4 className="widget-title">Social Links</h4>
                  <p>Join our social community to stay updated with the latest news and exclusive offers instantly!</p>
                  <div className="footer-social mt-30">
                    <ul>
                      <li>
                        <a href={social.facebook} target="_blank" rel="noreferrer">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                      <a href={social.youtube} target="_blank" rel="noreferrer">
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Singel Item */}
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="footer-bottom bg-dark text-light text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>{footer.CopyRight}</p>
              </div>
            </div>
          </div>
        </div>
        {/* End Footer Bottom */}
      </footer>

    </>
  );
};

export default Footer;
