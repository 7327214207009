import React from 'react';

const LeadGif = () => {
    return (
        <div>
             <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="site-heading text-center">
   
                <h2 className="title">Client's Bank - Huntcrow</h2>
                <div className="devider" />
              </div>
            </div>
            <img src="https://attherate.dev/huntcrow/Lead.gif" alt="" />
          </div>
        </div>
        </div>
    );
};

export default LeadGif;